import React from "react";
import NextLink from "next/link";
import cn from "classnames";
import { times } from "lodash/fp";
import { PageType } from "storefront/Analytics/Event";
import { CollectionDisplay } from "storefront/Collection";
import { PHOTO_PLACEHOLDER_URL } from "storefront/Listing";
import useModuleAnalytics from "storefront/hooks/useModuleAnalytics";
import ImageV2 from "storefront/components/ImageV2";
import Subhead from "storefront/components/Subhead";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./index.module.scss";

const PHOTO_SLOTS = 5;

type Props = {
  collection: CollectionDisplay;
  algorithmId: string;
  position: number;
  pageType: PageType;
  className?: string;
};

const CollectionModule = ({
  collection,
  algorithmId,
  position,
  pageType,
  className,
}: Props) => {
  const analytics = useModuleAnalytics({
    collection,
    algorithmId,
    position,
    from: pageType,
  });

  return (
    <NextLink
      href={`/collections/${collection.id}`}
      className={cn(styles.link, className)}
      ref={analytics?.ref}
      onClick={analytics?.onClick}
    >
      <Subhead className={styles.title}>{collection.title}</Subhead>

      <div className={styles.images}>
        {times((index) => {
          const photo = collection.displayPhotos[index];
          const renderOverlay =
            index === PHOTO_SLOTS - 2 || index === PHOTO_SLOTS - 1;

          return (
            <div
              className={styles[`image-${index + 1}`]}
              key={`${photo?.id}-${photo?.url}`}
            >
              <ImageV2
                src={photo?.url || PHOTO_PLACEHOLDER_URL}
                alt={photo?.url || PHOTO_PLACEHOLDER_URL}
                fill
                fit="crop"
                sizes="175px"
                rotate={photo?.rotate}
                priority
                className={styles.img}
              />

              {renderOverlay ? (
                <div className={styles.viewMore}>+ View More</div>
              ) : null}
            </div>
          );
        }, PHOTO_SLOTS)}
      </div>
    </NextLink>
  );
};

export default CollectionModule;
