import React from "react";
import { isEmpty } from "lodash/fp";
import { Id } from "storefront/lib/Id";
import { PageType } from "storefront/Analytics/Event";
import {
  ModuleEntry,
  EMBEDDED_FEATURE,
  PERSONALIZED_COLLECTION_GRID,
  PERSONALIZED_COLLECTION_SINGLE,
  TICKER,
  EMBEDDED_RECENTLY_VIEWED,
} from "storefront/Contentful/types";
import { getId } from "storefront/Contentful/Entry";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import useAuthentication from "storefront/hooks/useAuthentication";
import useCollectionsForUser from "storefront/hooks/useCollectionsForUser";
import { CollectionDisplay } from "storefront/Collection";
import LoadingHomepageContent from "storefront/components/Homepage/ContentfulHomepage/LoadingHomepageContent";
import { getRecentlyViewedListings } from "storefront/components/EmbeddedRecentlyViewed";
import AlertBanner, { ALERT_BANNER } from "storefront/components/AlertBanner";
import BigVideoModule, { BIG_VIDEO } from "../BigVideoModule";
import CategoryGrid, { CATEGORY_GRID } from "../CategoryGrid";
import DCOBlock, { DCO_BLOCK } from "../DCOBlock";
import DCOEntryPoint, { DCO_ENTRY_POINT } from "../DCOEntryPoint";
import DesignerGrid, { DESIGNER_GRID } from "../DesignerGrid";
import DesignersForYou, { DESIGNERS_FOR_YOU } from "../DesignersForYou";
import DiscoverDailyCarousel, {
  DISCOVER_DAILY_CAROUSEL,
} from "../DiscoverDailyCarousel";
import EmbeddedWrapper from "../EmbeddedWrapper";
import FeaturedCapsules, { FEATURED_CAPSULES } from "../FeaturedCapsules";
import FeaturedContentModule from "../FeaturedContentModule";
import { FEATURED_CONTENT } from "../FeaturedContentModule/FeaturedContentModuleEntry";
import FeedCarousel, { FEED_CAROUSEL } from "../FeedCarousel";
import FeedGrid, { FEED_GRID } from "../FeedGrid";
import FullWidthCTA, { FULL_WIDTH_CTA } from "../FullWidthCTA";
import GuideScreen from "../Guide/GuideScreen";
import { GUIDE_SCREEN } from "../Guide/types";
import ListingsFromFeed, { LISTINGS_FROM_FEED } from "../ListingsFromFeed";
import RecentlyViewed, { RECENTLY_VIEWED } from "../RecentlyViewed";
import VisualFilters, { VISUAL_FILTERS } from "../VisualFilters";
import ContentfulErrorBoundary from "../ContentfulModuleWrapper/ContentfulErrorBoundary";
import HeroCarousel, { HERO_CAROUSEL } from "../HeroCarousel";
import { NUM_GRID_COLLECTIONS } from "../../PersonalizedCollectionGrid";

type Props = {
  modules: Array<ModuleEntry>;
  from: string;
  pageType?: PageType;
  pageTypeIdentifier?: Id;
  pageTypeName?: string;
};

type ModuleComponentProps = {
  entry?: ModuleEntry;
  position?: number;
  from?: string;
  pageType?: PageType;
  pageTypeIdentifier?: Id;
  pageTypeName?: string;
  collections?: Array<CollectionDisplay>;
  algorithmId?: string;
  recentlyViewedListings?: Array<AlgoliaListing>;
};

const contentTypeToComponent: Record<
  string,
  React.ComponentType<ModuleComponentProps>
> = {
  [ALERT_BANNER]: AlertBanner,
  [BIG_VIDEO]: BigVideoModule,
  [CATEGORY_GRID]: CategoryGrid,
  [DCO_BLOCK]: DCOBlock,
  [DCO_ENTRY_POINT]: DCOEntryPoint,
  [DESIGNER_GRID]: DesignerGrid,
  [DESIGNERS_FOR_YOU]: DesignersForYou,
  [DISCOVER_DAILY_CAROUSEL]: DiscoverDailyCarousel,
  [EMBEDDED_FEATURE]: EmbeddedWrapper,
  [FEATURED_CAPSULES]: FeaturedCapsules,
  [FEATURED_CONTENT]: FeaturedContentModule,
  [FEED_CAROUSEL]: FeedCarousel,
  [FEED_GRID]: FeedGrid,
  [FULL_WIDTH_CTA]: FullWidthCTA,
  [GUIDE_SCREEN]: GuideScreen,
  [HERO_CAROUSEL]: HeroCarousel,
  [LISTINGS_FROM_FEED]: ListingsFromFeed,
  [RECENTLY_VIEWED]: RecentlyViewed,
  [VISUAL_FILTERS]: VisualFilters,
};

function isPersonalizedContent(entry: ModuleEntry): boolean {
  return (
    entry.contentType === EMBEDDED_FEATURE &&
    (entry.fields.moduleId === PERSONALIZED_COLLECTION_GRID ||
      entry.fields.moduleId === PERSONALIZED_COLLECTION_SINGLE)
  );
}

function isPersonalizedCollectionGrid(entry: ModuleEntry): boolean {
  return (
    entry.contentType === EMBEDDED_FEATURE &&
    entry.fields.moduleId === PERSONALIZED_COLLECTION_GRID
  );
}

function isEmbeddedRecentlyViewed(entry: ModuleEntry): boolean {
  return (
    entry.contentType === EMBEDDED_FEATURE &&
    entry.fields.moduleId === EMBEDDED_RECENTLY_VIEWED
  );
}

function isTicker(entry: ModuleEntry): boolean {
  return (
    entry.contentType === EMBEDDED_FEATURE && entry.fields.moduleId === TICKER
  );
}

function positionIncrementByEntry(
  entry: ModuleEntry,
  collections?: Array<CollectionDisplay>,
  collectionIndex?: number,
  recentlyViewedListings?: Array<AlgoliaListing>,
): number {
  if (isTicker(entry)) return 0;

  if (isPersonalizedCollectionGrid(entry))
    return collections?.slice(0, NUM_GRID_COLLECTIONS).length || 0;

  if (
    isPersonalizedContent(entry) ||
    (isEmbeddedRecentlyViewed(entry) && isEmpty(recentlyViewedListings))
  ) {
    return collectionIndex !== undefined && collections?.[collectionIndex]
      ? 1
      : 0;
  }

  return 1;
}

const Modules = ({
  modules,
  from,
  pageType,
  pageTypeIdentifier,
  pageTypeName,
}: Props) => {
  const auth = useAuthentication();

  const withPersonalizedContent = modules.some((entry) =>
    isPersonalizedContent(entry),
  );

  const personalizedCollectionsResource = useCollectionsForUser(
    withPersonalizedContent ? auth : undefined,
  );

  const [recentlyViewedListings, setRecentlyViewedListings] = React.useState<
    Array<AlgoliaListing> | undefined
  >(undefined);

  const withRecentlyViewed = modules.some((entry) =>
    isEmbeddedRecentlyViewed(entry),
  );

  React.useEffect(() => {
    if (!withRecentlyViewed || auth.type === "Loading") return;

    getRecentlyViewedListings(auth).then(setRecentlyViewedListings);
  }, [auth, withRecentlyViewed]);

  if (
    auth.type === "Loading" ||
    (withPersonalizedContent &&
      personalizedCollectionsResource.type === "Loading") ||
    (withRecentlyViewed && recentlyViewedListings === undefined)
  )
    return <LoadingHomepageContent />;

  const personalizedCollections =
    withPersonalizedContent &&
    personalizedCollectionsResource.type === "Completed"
      ? personalizedCollectionsResource.value
      : undefined;

  const collections = personalizedCollections?.collections;
  const algorithmId = personalizedCollections?.algorithmId;

  let position = 0;
  let collectionsIndex = 0;

  return (
    <>
      {modules.map((entry) => {
        const ModuleComponent = contentTypeToComponent[entry.contentType];
        if (!ModuleComponent) return null;

        const currentModulePosition = position;
        const currentCollectionsIndex = collectionsIndex;

        const positionIncrement = positionIncrementByEntry(
          entry,
          collections,
          currentCollectionsIndex,
          recentlyViewedListings,
        );

        position += positionIncrement;

        let collectionsForModule;

        if (isPersonalizedCollectionGrid(entry)) {
          collectionsForModule = collections?.slice(0, NUM_GRID_COLLECTIONS);
        } else if (
          isPersonalizedContent(entry) ||
          (isEmbeddedRecentlyViewed(entry) && isEmpty(recentlyViewedListings))
        ) {
          collectionsIndex += positionIncrement;

          const collectionAtIndex = collections?.[currentCollectionsIndex];
          collectionsForModule = collectionAtIndex
            ? [collectionAtIndex]
            : undefined;
        }

        return (
          <ContentfulErrorBoundary
            key={`${getId(entry)}-${currentModulePosition}`}
          >
            <ModuleComponent
              key={`${getId(entry)}-${currentModulePosition}`}
              entry={entry}
              position={currentModulePosition}
              from={from}
              pageType={pageType}
              pageTypeIdentifier={pageTypeIdentifier}
              pageTypeName={pageTypeName}
              collections={collectionsForModule}
              algorithmId={algorithmId}
              recentlyViewedListings={recentlyViewedListings}
            />
          </ContentfulErrorBoundary>
        );
      })}
    </>
  );
};

export default Modules;
