import React from "react";
import NextLink from "next/link";
import { chunk } from "lodash/fp";
import { PageType } from "storefront/Analytics/Event";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import fetchListingsByIds from "storefront/Algolia/Listings/fetchByIds";
import { Authentication } from "storefront/Authentication";
import getRecentlyViewed from "storefront/RecentlyViewed/get";
import useModuleAnalytics from "storefront/hooks/useModuleAnalytics";
import listingPath from "storefront/paths/listing";
import {
  Carousel,
  CarouselContent,
  CarouselNavDots,
  CarouselNavNext,
  CarouselNavPrev,
  CarouselSlide,
} from "storefront/components/CarouselNext";
import ImageV2 from "storefront/components/ImageV2";
import Footnote from "storefront/components/Footnote";
import Subhead from "storefront/components/Subhead";
import Price from "storefront/components/Price";
import styles from "./index.module.scss";

const MIN_LISTINGS = 5;

export function getRecentlyViewedListings(
  auth: Authentication,
): Promise<Array<AlgoliaListing>> {
  return getRecentlyViewed(
    auth.type === "Authenticated" ? auth.user.id : null,
  ).then((ids) => {
    if (ids.size >= MIN_LISTINGS) {
      return fetchListingsByIds(Array.from(ids), "default");
    }
    return Promise.resolve([]);
  });
}

type Props = {
  position: number;
  pageType: PageType;
  recentlyViewedListings: Array<AlgoliaListing>;
};

const EmbeddedRecentlyViewed = ({
  position,
  pageType,
  recentlyViewedListings,
}: Props) => {
  const analytics = useModuleAnalytics({
    name: "Recently Viewed",
    type: "Recently Viewed",
    position,
    from: pageType,
  });

  return (
    <div className={styles.module} ref={analytics?.ref}>
      <Subhead className={styles.title}>Recently Viewed</Subhead>

      <Carousel loop>
        <CarouselContent>
          {chunk(MIN_LISTINGS, recentlyViewedListings).map((listings) => {
            return (
              <CarouselSlide>
                <div>
                  {listings.map((listing) => {
                    return (
                      <NextLink
                        href={listingPath(listing)}
                        target="_blank"
                        rel="noreferrer"
                        onClick={analytics?.onClick}
                        className={styles.listing}
                      >
                        <ImageV2
                          src={listing.coverPhoto.url}
                          alt={listing.title}
                          width={72}
                          height={72}
                          fit="crop"
                          rotate={listing.coverPhoto.rotate}
                          className={styles.thumbnail}
                        />

                        <div className={styles.info}>
                          <Footnote>{listing.title}</Footnote>

                          <Price size="small" listing={listing} />
                        </div>
                      </NextLink>
                    );
                  })}
                </div>
              </CarouselSlide>
            );
          })}
        </CarouselContent>

        <div className={styles.nav}>
          <CarouselNavPrev className={styles.caret} />
          <CarouselNavDots className={styles.dots} />
          <CarouselNavNext className={styles.caret} />
        </div>
      </Carousel>
    </div>
  );
};

export default EmbeddedRecentlyViewed;
